<script setup lang="ts">
import {
  useDropzone,
  type FileRejectReason,
  type InputFile,
} from 'vue3-dropzone'
import UploadImg from '@/assets/images/upload.png'
interface Props {
  maxSize?: number | undefined
  accept?: HTMLInputElement['accept']
  multiple?: boolean
  disabled?: boolean
  inputPlaceholder?: string
}

type RejectionReasons =
  | 'file-invalid-type'
  | 'file-too-large'
  | 'file-too-small'
  | 'too-many-files'
// | string
// | boolean
// | null

export interface RejectedFileAndReasons {
  file: InputFile | File
  reasons: RejectionReasons[]
}

interface Emits {
  (e: 'files', v: File[]): void
  (e: 'rejections', v: RejectedFileAndReasons[]): void
  (e: 'the-event', v: Event): void
}

const props = withDefaults(defineProps<Props>(), {
  maxSize: undefined,
  multiple: !false,
  disabled: true,
  accept: 'image/jpg,image/jpeg,image/png,application/pdf',
  inputPlaceholder: '',
})
const emit = defineEmits<Emits>()
const { getRootProps, getInputProps, isDragActive } = useDropzone({
  onDrop,
  accept: props.accept,
  maxSize: props.maxSize,
  multiple: props.multiple,
  disabled: false,
})

function onDrop(
  files: File[],
  rejectReasons: FileRejectReason[],
  event: Event,
) {
  if (files && files.length) {
    emit('files', files)
  }
  if (rejectReasons && rejectReasons.length) {
    const mapped: RejectedFileAndReasons[] = rejectReasons.map((re) => {
      return {
        file: re.file,
        reasons: re.errors.map((err) => {
          if (typeof err === 'boolean') return true
          if (err === null) return null
          return err.code
        }),
      }
    })
    emit('rejections', mapped)
  }
  emit('the-event', event)
}
</script>

<template>
  <div
    v-bind="getRootProps()"
    class="grid cursor-pointer content-center rounded-[0.5rem] border border-[#E2E8F0] px-3 text-sm text-[#64748B]"
    :class="[
      {
        'outline outline-4 outline-[#758ec6]': isDragActive,
      },
    ]"
  >
    <input v-bind="getInputProps()" :placeholder="inputPlaceholder" />
    <div
      class="flex flex-col items-center justify-center gap-2 py-4 text-center"
    >
      <img :src="UploadImg" alt="upload" class="h-[2.625rem] w-[2.625rem]" />
      <p>Drag your file(s) to start uploading</p>
    </div>
    <div class="relative mx-auto w-full max-w-[12rem] px-4 py-2">
      <hr class="border-[#E2E8F0]" />
      <span
        class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-white px-4 text-center"
      >
        OR
      </span>
    </div>
    <div class="flex flex-col items-center gap-2 py-4">
      <span
        class="rounded-[6px] border border-primary px-4 py-1 text-sm font-semibold text-primary"
      >
        Browse files
      </span>
      <slot name="accept-text">
        <p class="text-center text-xs">
          Only .PDF, .PNG or .JPG supported (max. 5MB)
        </p>
      </slot>
    </div>
  </div>
</template>
